import { FC, SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { flatten, safeParse as valibotSafeParse } from 'valibot';

import { IOrgApi } from '../../OrganizationDetails';
import { useClipboard } from '../../../../hooks';
import { getUnifiedFormData } from '../../../../lib/getUnifiedFormData';
import { updateOrganizationDetailsAPI } from '../../../../api';
import { OrganizationApiKeyFormSchema } from '../../../../schemes';
import { ConfirmModal, FormElementError } from '../../../../components/common';

import './ApiDetailsCard.css';

interface IProps {
  organizationId: string;
  data: IOrgApi;
  onSuccess: () => void;
}

export const ApiDetailsCard: FC<IProps> = (props) => {
  const { organizationId, data, onSuccess } = props;
  const { t } = useTranslation();
  const { copy, copied } = useClipboard();

  const [formErrors, setFormErrors] = useState<any>();
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
  const [iconName, setIconName] = useState<'eye' | 'eye-off'>('eye-off');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);

  const changeApiKeyVisibility = () => {
    setIsApiKeyVisible((prev) => !prev);
    setIconName((prev) => (prev === 'eye' ? 'eye-off' : 'eye'));
  };

  const enableApiKeyHandler = async () => {
    try {
      if (!organizationId) {
        console.log('Organization ID is missing');
        return;
      }

      setIsPending(true);

      await updateOrganizationDetailsAPI(organizationId, {
        api: {
          enabled: true,
        },
      });

      onSuccess?.();
    } catch (error) {
      console.log(error);
    } finally {
      setIsConfirmModalOpen(false);
      setIsPending(false);
    }
  };

  const submitHandler = async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const parseFormData = getUnifiedFormData(target);

    const validate = valibotSafeParse(OrganizationApiKeyFormSchema, parseFormData);

    if (validate.success) {
      setFormErrors(undefined);

      try {
        setIsPending(true);

        await updateOrganizationDetailsAPI(organizationId, {
          api: {
            ...validate.output,
          },
        });

        onSuccess?.();
      } catch (error) {
        console.error(error);
      } finally {
        setIsPending(false);
      }
    } else {
      const flattenErrors = flatten<typeof OrganizationApiKeyFormSchema>(validate.issues);
      setFormErrors(flattenErrors.nested);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5>API Details</h5>
      </div>
      <div className="card-body">
        {data.enabled ? null : (
          <div className="alert alert-info mb-4" role="alert">
            <div className="d-flex align-items-center">
              <FeatherIcon icon="alert-circle" size={26} className="mr-2" />
              <div>
                <p className="mb-0">{t('Activate the feature if you need to use the API')}</p>
              </div>
            </div>
          </div>
        )}

        <div className="row row-gap-2 align-items-md-center justify-content-md-between">
          <div className="col-12 col-md-auto">
            <p className="mb-0">API Key</p>
          </div>
          <div className="col-12 col-md-auto">
            <div className="d-flex flex-column flex-md-row row-gap-3 column-gap-3">
              {data.enabled ? (
                <>
                  <div className="d-flex align-items-center">
                    <button type="button" className="btn btn-sm mr-2" onClick={changeApiKeyVisibility}>
                      <FeatherIcon icon={iconName} size={14} />
                    </button>
                    <p className={`apiKey text-break mb-0${isApiKeyVisible ? ' apiKey--visible' : ''}`}>
                      {data.api_key}
                    </p>
                  </div>
                  <button type="button" className="btn btn-sm btn-info" onClick={() => copy(data.api_key)}>
                    <div className="align-items-center">
                      <FeatherIcon icon="clipboard" size={16} className="mr-2" />
                      <span>{copied ? t('copied') : t('copy')}</span>
                    </div>
                  </button>
                </>
              ) : (
                <button type="button" className="btn btn-sm btn-primary" onClick={() => setIsConfirmModalOpen(true)}>
                  {t('enable')}
                </button>
              )}
            </div>
          </div>
        </div>

        {data.enabled ? (
          <>
            <hr className="my-4" />

            <h5 className="mb-4">Webhooks</h5>
            <form onSubmit={submitHandler}>
              <div className="mb-3">
                <label className="form-label">{t('webhook_url')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.['webhook_url']?.length ? ' is-invalid' : '')}
                  name="webhook_url"
                  placeholder="https://apiyourdomain.com/endpoint"
                  defaultValue={data.webhook_url}
                />
                <small>{t('webhookURLDescription')}</small>
                {formErrors?.['webhook_url']?.length ? (
                  <FormElementError text={formErrors['webhook_url'][0] || ''} />
                ) : null}
              </div>
              <div className="mb-3">
                <label className="form-label">{t('webhook_header')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.['webhook_header']?.length ? ' is-invalid' : '')}
                  name="webhook_header"
                  placeholder="Bearer Rz3aZxYNQKowgRva"
                  defaultValue={data.webhook_header}
                />
                <small>
                  <Trans
                    i18nKey="webhookHeaderDescription"
                    components={{
                      ExtLink: (
                        <Link to="https://developer.mozilla.org/docs/Web/HTTP/Headers/Authorization" target="_blank" />
                      ),
                    }}
                  />
                </small>
                {formErrors?.['webhook_header']?.length ? (
                  <FormElementError text={formErrors['webhook_header'][0] || ''} />
                ) : null}
              </div>
              <button type="submit" className="btn btn-primary mt-2" disabled={isPending}>
                {t('save')}
              </button>
            </form>
          </>
        ) : null}
      </div>

      {isConfirmModalOpen ? (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={t('confirmAction')}
          text={t('confirmApiKeyEnable')}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={enableApiKeyHandler}
          confirmText={isPending ? t('sending') : t('confirmEnableApiKey')}
        />
      ) : null}
    </div>
  );
};
