import type { InferOutput } from 'valibot';

import { pipe, object, string, url, boolean, optional, union, literal } from 'valibot';

export const OrganizationApiKeyFormSchema = object({
  enabled: optional(boolean()),
  api_key: optional(string('Please enther the API key')),
  webhook_url: optional(
    union([literal(''), pipe(string(), url('Please enter the correct url. Should start with https://'))])
  ),
  webhook_header: optional(string('Please enter the Webhook header')),
});

export type IOrganizationApiKeyForm = InferOutput<typeof OrganizationApiKeyFormSchema>;
