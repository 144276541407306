import { AxiosError } from 'axios';
import { toast } from '../components/common';

export interface ErrorResponse {
  code: number;
  message: string;
  success: boolean;
}

export const axiosApiErrorHandler = (error: AxiosError<ErrorResponse>) => {
  if (error.response) {
    const data = error.response.data;
    const status = error.response.status;
    const headers = error.response.headers;
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(data);
    console.log(status);
    console.log(headers);

    if (data.code === 120) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      window.location.assign('/login-email');
    }

    toast({
      variant: 'error',
      title: `Error code: ${data.code ?? error.code}`,
      description: data.message ?? error.message,
    });
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    toast({
      variant: 'error',
      title: 'Ooops!',
      description: 'An error occurred',
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Api error', error.message);
    toast({
      variant: 'error',
      title: 'Ooops!',
      description: 'Internal API error occurred',
    });
  }
  console.log(error.config);
};
